import $ from 'jquery';
import 'slick-carousel/slick/slick';
window.$ = $;
window.jQuery = $;

document.addEventListener('DOMContentLoaded', () => {
  const toggleClass = (selector, cls) => {
    const els = document.querySelectorAll(selector);
    els.forEach((el) => {
      el.classList.toggle(cls)
    })
  }

  if (document.querySelector('.navbar-burger .burger')) {
    document.querySelector('.navbar-burger .burger').addEventListener('click', (e) => {
      e.preventDefault()
      toggleClass('.navbar-burger, .header .navbar-menu', 'is-active')
    })
  }

  if (document.querySelector('.navbar-burger .search')) {
    document.querySelector('.navbar-burger .search button').addEventListener('click', (e) => {
      if (document.querySelector('.navbar-burger .search').classList.contains('is-active') && document.querySelector('.navbar-burger .search .input').value != "") {
        return true;
      } else {
        e.preventDefault()
        toggleClass('.navbar-burger .search', 'is-active')
      }
    })
  }
  document.querySelectorAll('.navbar .navbar-menu > .navbar-item').forEach((e) => {
    if (e.querySelector('.navbar-item') && e.querySelector('a')) {
      e.querySelector('a').addEventListener('click', (event) =>{
        if (document.body.clientWidth <= 1024) {
          // go to link is dropdown is active
          if (e.querySelector('.navbar-dropdown').classList.contains('is-active')) {
            return true;
          } else {
            event.preventDefault();
            e.querySelector('.navbar-dropdown').classList.add('is-active');
          }
        }
      })
    }
  })


  const modalTogglers = document.querySelectorAll('.modal-close, .modal-background');
  modalTogglers.forEach((el) => {
    el.addEventListener('click', (event) => {
      event.preventDefault()
      el.parentElement.classList.toggle('is-active')
    })
  })

  const togglers = document.querySelectorAll('[data-toggler]')
  if (togglers.length) {
    togglers.forEach(el => {
      el.addEventListener('click', (e) => {
        e.preventDefault()
        const $target = e.currentTarget;
        const selector = $target.dataset.toggler;
        toggleClass(selector, 'is-active')
      })
    })
  }

  const tables = document.querySelectorAll('table')
  if (tables.length) {
    tables.forEach((el) => el.classList.add('table','is-bordered', 'is-striped'))
  }

  $('.tabs li').on('click', (e) => {
    const tab = e.currentTarget.dataset.tab
    // hide other tabs
    $('.tabs-content .is-active, .tabs li.is-active').removeClass('is-active')
    $(tab).addClass('is-active')
    $(e.currentTarget).addClass('is-active');

  })

  $('.img-slider').slick({
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true
  })

  $('.gallery').slick({
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  })

  if ($('#product').length) {
    $('.img-gallery').slick({
      autoplay: true,
      autoplaySpeed: 5000,
      asNavFor: '.img-thumbs',
      arrows: true
    })
    $('.img-thumbs').slick({
      slidesToShow: 4,
      arrows: false,
      slidesToScroll: 4,
      asNavFor: '.img-gallery',
      dots: true
    })
  }


  let dropdowns = document.querySelectorAll('.navbar-item > .menu-list');
  let wrapper = document.createElement('div');
  [...dropdowns].forEach((e) => e.parentElement.classList.add('is-hoverable'))
  wrapper.classList.add('navbar-dropdown')
  wrapper.wrap(dropdowns)

});


// Wrap an HTMLElement around each element in an HTMLElement array.
HTMLElement.prototype.wrap = function(elms) {
  // Convert `elms` to an array, if necessary.
  if (!elms.length) elms = [elms];

  // Loops backwards to prevent having to clone the wrapper on the
  // first element (see `child` below).
  for (var i = elms.length - 1; i >= 0; i--) {
      var child = (i > 0) ? this.cloneNode(true) : this;
      var el    = elms[i];

      // Cache the current parent and sibling.
      var parent  = el.parentNode;
      var sibling = el.nextSibling;

      // Wrap the element (is automatically removed from its current
      // parent).
      child.appendChild(el);

      // If the element had a sibling, insert the wrapper before
      // the sibling to maintain the HTML structure; otherwise, just
      // append it to the parent.
      if (sibling) {
          parent.insertBefore(child, sibling);
      } else {
          parent.appendChild(child);
      }
  }
};
